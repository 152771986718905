var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-control-container"},[_c('base-control-wrapper',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
var label = ref.label;
return [_vm._t("header",null,{"label":label})]}},{key:"form-control",fn:function(ref){return [_c('v-select',{ref:_vm.id,staticClass:"base-control-container-select",class:{'multiple-select': _vm.$attrs['multiple']},attrs:{"menu-props":{
          contentClass: _vm.$attrs['multiple'] ? 'multiple-select--menu' : '',
          nudgeTop: 1
        },"id":_vm.id,"data-ruid":("select-" + _vm.id),"disabled":_vm.disabled,"height":_vm.$attrs.height || 50,"item-text":_vm.$attrs['item-text'],"item-value":_vm.$attrs['item-value'],"items":_vm.items,"loading":_vm.loading,"multiple":_vm.$attrs['multiple'],"placeholder":_vm.$attrs.placeholderText,"readonly":_vm.disabled,"value":_vm.$attrs.value,"appendIcon":"keyboard_arrow_down","dense":"","flat":"","hide-details":"","persistent-hint":"","return-object":"","single-line":"","solo":""},on:{"input":function($event){return _vm.$emit('input',$event)}},scopedSlots:_vm._u([(_vm.$attrs['multiple'])?{key:"selection",fn:function(ref){
        var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(((_vm.$attrs.value.length) + " selected")))]):_vm._e()]}}:null,{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.noDataAvailable'))+" ")]},proxy:true}],null,true)})]}},{key:"errors",fn:function(ref){
        var errors = ref.errors;
return [_vm._t("errors",null,{"errors":errors})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'base-control-wrapper',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }