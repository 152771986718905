



































































































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { classToClass } from 'class-transformer';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Team from '@improve/common-utils/src/model/Team';
import User from '@improve/common-utils/src/model/User';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import GaleraRoles from '@improve/common-utils/src/types/GaleraRoles';
import { TeamMemberAction } from '@improve/common-utils/src/types/TeamMemberAction';
import BaseTeamMemberCard from '../widgets/BaseTeamMemberCard.vue';
import BaseButton from '../widgets/BaseButton.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseUserSelectorModal from '../user/BaseUserSelectorModal.vue';
import BaseSelect from '../core/BaseSelect.vue';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseTextInput from '../core/BaseTextInput.vue';

@Component({
  name: 'BaseTeam',
  components: {
    BaseTextInput,
    BaseSelect,
    BaseTextArea,
    BaseButton,
    BaseAddButton,
    BaseUserSelectorModal,
    BaseTeamMemberCard
  }
})
export default class BaseTeam extends Vue {
  @Prop({ default: null }) readonly team!: Team;

  @Getter allTeamsById!: (id: string) => Team;

  @Action fetchTeamMembers!: ActionMethod;

  @Action fetchUsersByBatch!: ActionMethod;

  private teamToUpdate: Team | null = null;

  private members: Array<User> = [];

  private owners: Array<User> = [];

  private allExistingMembers: Map<string, number> = new Map<string, number>();

  private allUserChanges: Map<string, TeamMemberAction> = new Map<string, TeamMemberAction>();

  private showOwners = false;

  private showMembers = false;

  async created(): Promise<void> {
    this.setTeam();
    await this.fetchMembers();
  }

  setTeam(): void {
    this.teamToUpdate = classToClass(this.team);
  }

  async fetchMembers(): Promise<void> {
    this.allExistingMembers = await this.fetchTeamMembers(this.teamToUpdate?.id);
    const memberUsers: Array<User> = await this.fetchUsersByBatch(
      Array.from(this.allExistingMembers.keys())
    );

    memberUsers.forEach((user: User) => {
      const level = user.id && this.allExistingMembers.has(user.id)
        ? this.allExistingMembers.get(user.id)!
        : 0;
      this.allUserChanges.set(user.id!, { action: 0, level, user });
    });
    this.resetUsersPerType();
  }

  resetUsersPerType(): void {
    this.owners = this.getUsersByLevel(GaleraRoles.OwnerUserLevel);
    this.members = this.getUsersByLevel(GaleraRoles.MemberUserLevel);
  }

  userUnitName(member: User): string {
    const unit = member.unit && this.allTeamsById(member.unit);
    return unit ? `${unit.name}` : '';
  }

  getUsersByLevel(level: number): User[] {
    return Array.from(this.allUserChanges.values())
      .filter((memberInfo) => memberInfo.action >= 0 && memberInfo.level === level)
      .map((memberInfo) => memberInfo.user);
  }

  addOwnerUsers(users: Array<User>): void {
    this.changesForUserAdd(users, GaleraRoles.OwnerUserLevel);
    this.showOwners = false;
  }

  removeOwner(user: User): void {
    this.changesForUserRemoval(user, GaleraRoles.OwnerUserLevel);
  }

  addMemberUsers(users: Array<User>): void {
    this.changesForUserAdd(users, GaleraRoles.MemberUserLevel);
    this.showMembers = false;
  }

  removeMember(user: User): void {
    this.changesForUserRemoval(user, GaleraRoles.MemberUserLevel);
  }

  changesForUserAdd(users: Array<User>, level: number): void {
    users.forEach((u) => {
      let memberInfo;
      if (!this.allExistingMembers.has(u.id!)) {
        memberInfo = { action: 1, level, user: u };
      } else {
        const oldLevel = this.allExistingMembers.get(u.id!);
        memberInfo = this.allUserChanges.get(u.id!)!;
        memberInfo.action = oldLevel === level ? 0 : memberInfo.action + 1;
        memberInfo.level = level;
      }
      this.allUserChanges.set(u.id!, memberInfo);
    });
    this.resetUsersPerType();
  }

  changesForUserRemoval(user: User, level: number): void {
    if (!this.allExistingMembers.has(user.id!)) {
      this.allUserChanges.delete(user.id!);
    } else {
      const memberInfo = this.allUserChanges.get(user.id!) || { action: 0, level, user };
      memberInfo.action = -1;
      this.allUserChanges.set(user.id!, memberInfo);
    }
    this.resetUsersPerType();
  }

  memberCardOptions(isMember: boolean): Array<MenuOption> {
    const options: Array<MenuOption> = [];
    if (isMember) {
      options.push({
        title: this.$t('menu.makeOwner').toString(),
        value: 'makeOwner'
      });
    } else {
      options.push({
        title: this.$t('menu.makeTeamMember').toString(),
        value: 'makeTeamMember'
      });
    }
    options.push({
      title: this.$t('menu.remove').toString(),
      value: 'remove'
    });
    return options;
  }

  onMenuOptionsClick(user: User, isMember: boolean, option: MenuOption): void {
    switch (option.value) {
      case 'makeOwner': {
        this.addOwnerUsers([user]);
        break;
      }
      case 'makeTeamMember': {
        this.addMemberUsers([user]);
        break;
      }
      case 'remove': {
        if (isMember) {
          this.removeMember(user);
        } else {
          this.removeOwner(user);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  prepareTeam(): { team: Team | null; actions: Map<string, TeamMemberAction> } {
    return {
      team: this.teamToUpdate,
      actions: this.allUserChanges
    };
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }
}
