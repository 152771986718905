



























































import { Component, Prop } from 'vue-property-decorator';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseSelect',
  components: {
    BaseControlWrapper
  }
})
export default class BaseSelect extends BaseFormControl {
  @Prop({ default: () => [] }) items!: Array<string>;

  created(): void {
    super.created();
  }
}
